export function verifyInt(num) {
  if (!num) {
    return null;
  }
  if (parseInt(num, 10)) {
    return parseInt(num, 10);
  }
  return null;
}
// 深度合并对象 防止后端返回对象为null 报错
export function deepObjectMerge(initObj, formObj) {
  const obj = {};
  if (formObj === null || formObj === undefined) {
    Object.keys(initObj).forEach((key) => {
      obj[key] = Object.prototype.toString.call(initObj[key]) === '[object Object]'
        ? deepObjectMerge(initObj[`${key}`]) : initObj[key];
    });
  } else {
    Object.keys(initObj).forEach((key) => {
      if (key === 'levelMap') {
        obj[key] = formObj[key];
      } else {
        obj[key] = Object.prototype.toString.call(initObj[key]) === '[object Object]'
          ? deepObjectMerge(initObj[key], formObj[key]) : formObj[key];
      }
    });
  }

  return obj;
}
