const selectBoolean = [
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
];
const selectInteger = [
  {
    value: 1,
    label: '是',
  },
  {
    value: 0,
    label: '否',
  },
];

const GOODS_PICK_TYPE = [
  {
    value: 'TOTAL_PICK',
    label: '整箱分拣',
  },
  {
    value: 'PART_PICK',
    label: '拆零分拣',
  },
];
export { selectBoolean, selectInteger, GOODS_PICK_TYPE };
