import { getGoodsLevelTree, ruleStockAllocation } from './api';

export default {
  data() {
    return {
      cascader: null,
      ruleStockAllocationList: [],
    };
  },
  computed: {
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
  },
  mounted() {
    this.getCascader();
  },
  methods: {
    readNodes(nodes = []) {
      const arr = [];
      nodes.forEach((item, index) => {
        arr.push({ label: item.name, value: item.name });
        if (item.children && item.children.length > 0) {
          arr[index].children = this.readNodes(item.children);
        }
      });
      return arr;
    },
    async getCascader() {
      const resp = await getGoodsLevelTree({});
      this.cascader = this.readNodes(resp);
    },
    async getRuleStockAllocation(query) {
      const resp = await ruleStockAllocation({
        page: 1,
        size: 10,
      }, { description: query });
      return resp.records.map((item) => ({
        label: item.description,
        value: item.id,
      }));
    },
  },
};
