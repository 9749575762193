<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.getDetail"
    :before-close="handleClose"
    :track="{
      name_zh: `基础数据/货主相关/货主货品信息/抽屉-${disabled ? '详情': isEdit ? '编辑': '新增'}`,
      trackName: $route.path
    }"
    destroy-on-close
    title="货主货品信息"
    size="1112px"
  >
    <nh-drawer-container title="货品基础信息">
      <skuInfo
        ref="skuInfo"
        :form="form"
        :disabled="disabled"
        :is-edit="isEdit"
        @updateForm="updateForm"
      />
    </nh-drawer-container>
    <nh-drawer-container title="计量信息">
      <metering
        ref="metering"
        :form="form"
        :disabled="disabled"
        @updateForm="updateForm"
      />
    </nh-drawer-container>
    <nh-drawer-container title="质控信息">
      <quality-control
        ref="quality"
        :form="form"
        :disabled="disabled"
        @updateForm="updateForm"
      />
    </nh-drawer-container>
    <nh-drawer-container title="存放规则">
      <deposit
        ref="deposit"
        :form="form"
        :disabled="disabled"
        @updateForm="updateForm"
      />
    </nh-drawer-container>
    <template #footer>
      <div v-if="!disabled" class="footer">
        <nh-button
          type="primary"
          :loading="loading.handleSubmit"
          @click="handleSubmit"
        >
          保 存
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import SkuInfo from './components/skuInfo.vue';
import Metering from './components/metering.vue';
import QualityControl from './components/qualityControl.vue';
import Deposit from './components/deposit.vue';

import { initForm } from './constant';
import { deepObjectMerge } from './unit';
import { warehouseGoodsUpsert, warehouseGoodsDetail } from './api';

export default {
  name: 'GoodsEdit',
  components: {
    SkuInfo,
    Metering,
    QualityControl,
    Deposit,
  },
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      id: null,
      warehouseCode: null,
      disabled: false,
      isEdit: false,
      drawerVisible: false,
      form: {},
      loading: {
        handleSubmit: false,
        getDetail: false,
      },
    };
  },
  methods: {
    init(row) {
      this.isEdit = false;
      this.form = deepObjectMerge(initForm);
      this.drawerVisible = true;
      const { id, warehouseCode, disabled } = row;
      this.disabled = !!disabled;
      if (id && warehouseCode) {
        this.isEdit = true;
        this.id = id;
        this.warehouseCode = warehouseCode;
        this.getDetail();
      }
    },
    async getDetail() {
      const resp = await warehouseGoodsDetail({
        id: this.id,
        warehouseCode: this.warehouseCode,
      });

      this.form = {
        ...deepObjectMerge(initForm, resp),
        goodsLevel: [resp.goodsLevel1, resp.goodsLevel2, resp.goodsLevel3],
      };
    },
    updateForm(data) {
      if (data.flag) {
        const changeVal = this.form.qualityControlExt.isShelfLife;
        const afterVale = data.isShelfLife;
        if (changeVal !== afterVale && afterVale !== undefined) {
          this.form.qualityControlExt.batchAttributeId = '';
          this.$refs.quality.handleRemoteRuleBatch();
        }

        this.form.qualityControlExt.isShelfLife = data.isShelfLife;
      }
      this.form = {
        ...this.form,
        ...data,
      };
    },
    async handleSubmit() {
      const skuInfo = await this.$refs.skuInfo.validateForm();
      const metering = await this.$refs.metering.validateForm();
      const quality = await this.$refs.quality.validateForm();
      const deposit = await this.$refs.deposit.validateForm();
      if (skuInfo && metering && quality && deposit) {
        const messageInfo = await warehouseGoodsUpsert({
          id: this.id,
          warehouseCode: this.warehouseCode,
          ...this.form,
        });
        this.handleClose();
        this.$emit('onSuccess');
        this.$message({
          type: messageInfo[0] ? 'warning' : 'success',
          message: messageInfo[0] || '操作成功!',
        });
      }
    },
    handleClose() {
      this.id = null;
      this.warehouseCode = null;
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  border-top: 1px solid #eee;
  padding: 8px 24px 8px 0;
  // background-color: #fff;
  text-align: right;
}
</style>
<style lang="scss">
.goods-info-comp {
  .el-select {
    width: 100%;
  }
}

.goods-sku-cascader {
  width: 100%;
}
</style>
