<template>
  <el-form
    ref="form"
    :model="formData"
    label-width="160px"
    :disabled="disabled"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="是否保质期商品:"
          prop="qualityControlExt.isShelfLife"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="formData.qualityControlExt.isShelfLife"
            placeholder="--请选择--"
            disabled
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in selectBoolean"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="保质期(天):"
          prop="qualityControlExt.shelfLife"
          :rules="[
            { required: formData.qualityControlExt.isShelfLife,
              message: '请输入', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="formData.qualityControlExt.shelfLife"
            :disabled="formData.qualityControlExt.isShelfLife === selectBoolean[1].value"
            @input="(val) => {
              formData.qualityControlExt.shelfLife = verifyInt(val);
              handleChange()
            }"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="允收期(天):"
          prop="qualityControlExt.dueInDay"
          :rules="[
            { required: formData.qualityControlExt.isShelfLife,
              message: '请输入', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="formData.qualityControlExt.dueInDay"
            :disabled="formData.qualityControlExt.isShelfLife === selectBoolean[1].value"
            @input="(val) => {
              formData.qualityControlExt.dueInDay = verifyInt(val);
              handleChange()
            }"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="允出期(天):"
          prop="qualityControlExt.dueOutDay"
          :rules="[
            { required: formData.qualityControlExt.isShelfLife,
              message: '请输入', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="formData.qualityControlExt.dueOutDay"
            :disabled="formData.qualityControlExt.isShelfLife === selectBoolean[1].value"
            @input="(val) => {
              formData.qualityControlExt.dueOutDay = verifyInt(val);
              handleChange()
            }"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="是否越库:"
          prop="qualityControlExt.isOverWarehouse"
        >
          <el-select
            v-model="formData.qualityControlExt.isOverWarehouse"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in selectBoolean"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="越库是否分拣:"
          prop="isOverWarehousePick"
          :rules="[
            { required:formData.qualityControlExt.isOverWarehouse?true:false
              , message: '请选择', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="formData.isOverWarehousePick"
            placeholder="--请选择--"
            :disabled="!formData.qualityControlExt.isOverWarehouse"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in selectInteger"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="是否解冻:"
          prop="qualityControlExt.isUnFreeze"
        >
          <el-select
            v-model="formData.qualityControlExt.isUnFreeze"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in selectBoolean"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="是否易碎:"
          prop="qualityControlExt.isFragile"
        >
          <el-select
            v-model="formData.qualityControlExt.isFragile"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in selectBoolean"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="温层:"
          prop="qualityControlExt.temperatureLayer"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="formData.qualityControlExt.temperatureLayer"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in zoneTemperature"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item label="安全库存:" prop="qualityControlExt.safeStock">
          <el-input
            v-model="formData.qualityControlExt.safeStock"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="批属性规则:"
          prop="qualityControlExt.batchAttributeId"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <nh-remote-select
            v-model="formData.qualityControlExt.batchAttributeId"
            :disabled="!form.shipperId"
            :remote-method="handleRemoteRuleBatch"
            :loading="loading.handleemoteRuleBatch"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in ruleBatchAttribute"
              :key="index"
              :label="item.ruleName"
              :value="item.id"
            />
          </nh-remote-select>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item
          label="单板品数(一托几品):"
          prop="blockGoodsNum"
        >
          <el-input
            v-model="formData.blockGoodsNum"
            @input="(val) => {
              formData.blockGoodsNum = verifyInt(val);
              handleChange()
            }"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="是否进口:"
          prop="qualityControlExt.isImport"
        >
          <el-select
            v-model="formData.qualityControlExt.isImport"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in selectBoolean"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { selectBoolean, selectInteger } from './constant';
import mixin from './mixin';
import { verifyInt, deepObjectMerge } from '../unit';
import { qualityControl } from '../constant';
import { getRuleBatchAttribute } from '../../api';
import { DICTIONARY } from '../../fileds';

export default {
  name: 'OwnerAboutGoodsInfoQualityControl',
  mixins: [mixin],
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateForm'],
  data() {
    return {
      isInit: true,
      formData: deepObjectMerge(qualityControl),
      selectBoolean,
      selectInteger,
      verifyInt,
      ruleBatchAttribute: [],
      loading: {
        handleRemoteRuleBatch: false,
      },
    };
  },
  watch: {
    form: {
      handler(val) {
        this.init(val);
      },
      deep: true,
    },
    'form.shipperId': {
      handler(newVal, oldVal) {
        if (oldVal !== null && newVal !== oldVal) {
          this.handleRemoteRuleBatch();
          this.formData.qualityControlExt.batchAttributeId = null;
        }
      },
    },
    'formData.qualityControlExt.isShelfLife': {
      handler(val) {
        if (val === selectBoolean[1].value) {
          this.formData.qualityControlExt.shelfLife = null;
          this.formData.qualityControlExt.dueInDay = null;
          this.formData.qualityControlExt.dueOutDay = null;
          this.$refs.form.clearValidate(['qualityControlExt.shelfLife',
            'qualityControlExt.dueInDay', 'qualityControlExt.dueOutDay',
          ]);
        }
      },
      deep: true,
    },
    'formData.qualityControlExt.batchAttributeId': {
      handler(val) {
        if (val) {
          for (let i = 0, l = this.ruleBatchAttribute.length; i < l; i += 1) {
            if (this.ruleBatchAttribute[i].id === val) {
              this.formData.qualityControlExt
                .batchAttributeName = this.ruleBatchAttribute[i].ruleName;
              break;
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    async init(val) {
      if (this.isInit) {
        this.isInit = false;
        await this.handleRemoteRuleBatch();
      }
      this.formData = val;
    },
    handleChange() {
      this.$emit('updateForm', this.formData);
    },
    async handleRemoteRuleBatch(query) {
      const { records } = await getRuleBatchAttribute({ size: 100, page: 1 }, {
        needWarehouseRule: true,
        shipperId: this.form.shipperId,
        ruleName: query,
      });
      let arr = [];
      const flag = this.form.qualityControlExt.isShelfLife;
      records.forEach((item) => {
        const itemValue = item;
        itemValue.flag = item.batchAttribute.some((e) => e.fieldCode === 'productDate' || e.fieldCode === 'expire_date');
        arr.push(itemValue);
      });

      arr = arr.filter((item) => item.status === DICTIONARY.ENABLED && item.flag === flag);
      this.ruleBatchAttribute = arr;
    },
    // 校验
    async validateForm() {
      try {
        await this.$refs.form.validate();
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
