<template>
  <el-form
    ref="form"
    :model="formData"
    label-width="160px"
    :disabled="disabled"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="整单位："
          prop="bigUnit"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="formData.bigUnit"
            placeholder="--请选择--"
            @change="handleChangeBigUnit"
          >
            <el-option
              v-for="(item, index) in GOODS_UNIT"
              :key="index"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="零单位："
          prop="smallUnit"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="formData.smallUnit"
            placeholder="--请选择--"
            @change="handleChangeSmallUnit"
          >
            <el-option
              v-for="(item, index) in GOODS_UNIT"
              :key="index"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="换算率："
          prop="conversionRate"
          :rules="[
            { required: true, message: '请输入', trigger: 'change' },
            { validator: (rule, value, callback)=>validatorM([1,100000],rule, value, callback),
              trigger: 'change' }
          ]"
        >
          <el-input
            v-model="formData.conversionRate"
            @input="(val) => {
              formData.conversionRate = verifyInt(val);
              handleChange()
            }"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="默认单位："
          prop="defaultUnit"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="formData.defaultUnit"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in defaultUnitOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="重量(整单位)："
          prop="weight"
          :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: (rule, value, callback)=>validatorM([0.001,100000],rule, value, callback),
              trigger: 'blur' }
          ]"
        >
          <el-input
            v-model="formData.weight"
            @change="(val) => {
              formData.weight = verifyInt(val * 1000) /1000;
              handleChange()
            }"
          >
            <template #append>
              KG
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="长(CM)："
          prop="goodsLength"
          :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: (rule, value, callback)=>validatorM([0.001,100000],rule, value, callback),
              trigger: 'blur' }
          ]"
        >
          <el-input
            v-model="formData.goodsLength"
            @change="(val) => {
              formData.goodsLength = verifyInt(val * 1000) /1000;
              handleChange()
            }"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="宽(CM)："
          prop="goodsWide"
          :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: (rule, value, callback)=>validatorM([0.001,100000],rule, value, callback),
              trigger: 'blur' }
          ]"
        >
          <el-input
            v-model="formData.goodsWide"
            @change="(val) => {
              formData.goodsWide = verifyInt(val * 1000) /1000;
              handleChange()
            }"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="高(CM)："
          prop="goodsHeight"
          :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: (rule, value, callback)=>validatorM([0.001, 100000],rule, value, callback),
              trigger: 'blur' }
          ]"
        >
          <el-input
            v-model="formData.goodsHeight"
            @change="(val) => {
              formData.goodsHeight = verifyInt(val * 1000) /1000;
              handleChange()
            }"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="含税单价(零单位)："
          prop="goodsPrice"
          :rules="[
            { validator: (rule, value, callback)=>validatorM([0.001, 100000],rule, value, callback),
              trigger: 'blur' }
          ]"
        >
          <el-input
            v-model="formData.goodsPrice"
            @change="(val) => {
              formData.goodsPrice = val === ''? null: verifyInt(val * 1000) /1000;
              handleChange()
            }"
          >
            <template #append>
              元
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="是否记录辅助单位：" prop="isCopyCode">
          <el-select
            v-model="formData.isCopyCode"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in selectInteger"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="辅助单位："
          prop="auxiliaryUnits"
          :rules="[
            { required: formData.isCopyCode,
              message: '请输入', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="formData.auxiliaryUnits"
            :disabled="formData.isCopyCode === selectInteger[1].value"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="计费单板数量："
          prop="veneerWeight"
          :rules="[
            { validator: (rule, value, callback)=>validatorM([1,100000],rule, value, callback),
              trigger: 'change' }
          ]"
        >
          <el-input
            v-model.number="formData.veneerWeight"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="计费重量(整单位)："
          prop="chargeWeight"
          :rules="[
            { validator: (rule, value, callback)=>validatorM([0.001,100000000000000000000]
                                                             ,rule, value, callback),
              trigger: 'change' }
          ]"
        >
          <el-input
            v-model="formData.chargeWeight"
            @change="(val) => {
              formData.chargeWeight = val === ''? null: verifyInt(val * 1000) /1000;
              handleChange()
            }"
          >
            <template #append>
              KG
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="分拣类型："
          prop="goodsPickType"
        >
          <el-select
            v-model="formData.goodsPickType"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in GOODS_PICK_TYPE"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { selectBoolean, selectInteger, GOODS_PICK_TYPE } from './constant';
import { verifyInt, deepObjectMerge } from '../unit';
import mixin from './mixin';
import { metering } from '../constant';

export default {
  name: 'OwnerAboutGoodsInfoMetering',
  mixins: [mixin],
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateForm'],
  data() {
    return {
      formData: deepObjectMerge(metering),
      verifyInt,
      selectBoolean,
      selectInteger,
      GOODS_PICK_TYPE,
      defaultUnitOptions: [],
      loading: {
        handleRemoteRuleBatch: false,
      },
    };
  },
  watch: {
    form: {
      handler(val) {
        this.formData = val;
        this.defaultUnitOptions = [];
        if (val.bigUnit) {
          this.defaultUnitOptions.push({ value: val.bigUnit, label: val.bigUnit });
        }
        if (val.smallUnit && val.smallUnit !== val.bigUnit) {
          this.defaultUnitOptions.push({ value: val.smallUnit, label: val.smallUnit });
        }
      },
      deep: true,
    },
    'formData.isCopyCode': {
      handler(val) {
        if (val === selectInteger[1].value) {
          this.formData.auxiliaryUnits = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    handleChange() {
      this.$emit('updateForm', this.formData);
    },
    handleChangeSmallUnit(val) {
      const form = {
        ...this.formData,
        smallUnit: val,
      };
      if (form.bigUnit === val) {
        form.defaultUnit = val;
        this.defaultUnitOptions = [{ value: val, label: val }];
      } else {
        form.defaultUnit = null;
        this.defaultUnitOptions = [
          { value: val, label: val },
          { value: form.bigUnit, label: form.bigUnit },
        ];
      }
      this.formData = form;
      this.$emit('updateForm', form);
    },
    handleChangeBigUnit(val) {
      const form = {
        ...this.formData,
        bigUnit: val,
      };
      if (form.smallUnit === val) {
        form.defaultUnit = val;
        this.defaultUnitOptions = [{ value: val, label: val }];
      } else {
        form.defaultUnit = null;
        this.defaultUnitOptions = [
          { value: val, label: val },
          { value: form.smallUnit, label: form.smallUnit },
        ];
      }
      this.formData = form;
      this.$emit('updateForm', form);
    },
    validatorM([min, max], rule, value, callback) {
      if (!value && value !== 0) {
        return callback();
      }
      if (max !== undefined && value > max) {
        return callback(new Error(`请输入小于等于${max}的数`));
      }
      if (min !== undefined && value < min) {
        return callback(new Error(`请输入大于等于${min}的数`));
      }
      return callback();
    },
    // 校验
    async validateForm() {
      try {
        await this.$refs.form.validate();
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
