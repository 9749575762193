const DICTIONARY = {
  ENABLED: 'ENABLED', // 批属性生效
  STATUS: 'status',
  SUCCESS: 'success',
};
const SHELF_LIFE = [
  {
    label: '是',
    value: true,
  }, {
    label: '否',
    value: false,
  },
];
const UN_FREEZE = [
  {
    label: '是',
    value: true,
  }, {
    label: '否',
    value: false,
  },
];
const WAREHOUSE_STATUS = [
  {
    label: '启用',
    code: '1',
    value: 'ENABLED',
  }, {
    label: '禁用',
    code: '0',
    value: 'DISABLED',
  },
];
export {
  DICTIONARY, SHELF_LIFE, UN_FREEZE, WAREHOUSE_STATUS,
};
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  minWidth: 180,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 120,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '货品条码',
  prop: 'internationalCode',
  minWidth: 160,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 180,
}, {
  label: '品牌名称',
  prop: 'partnerCode',
  minWidth: 120,
}, {
  label: '供应商',
  prop: 'partnerName',
  minWidth: 120,
}, {
  label: '货品分类',
  prop: 'goodsTypeName',
  minWidth: 180,
}, {
  label: '温层',
  prop: 'temperatureLayerName',
  minWidth: 120,
}, {
  label: '状态',
  prop: 'statusName',
  minWidth: 120,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 120,
},
];
