<template>
  <el-form
    ref="form"
    :model="formData"
    label-width="160px"
    :rules="rules"
    :disabled="disabled"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item
          class="myFormItem"
          label="单板数量："
          prop="storageRuleExt.veneerNum"
          :error="errorVeneerNumMsg"
        >
          <el-input
            v-model="formData.storageRuleExt.veneerNum"
            class="width120"
            disabled
            @input="(val) => {
              formData.storageRuleExt.veneerNum = verifyInt(val);
              handleChange()
            }"
          />
          <span class="start">{{ form.bigUnit }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-checkbox
          v-model="checked"
          label="标准码盘"
          @change="palletRuleChange"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="checked?24:8">
        <el-form-item
          v-if="checked"
          label="码盘规则："
          prop="storageRuleExt.palletRule.totalLevel"
          :rules="[
            {validator: (rule, value, callback)=>validateLevel(rule, value, callback),
             trigger: 'blur' },
          ]"
        >
          <span class="start">共</span>
          <el-input
            v-model="formData.storageRuleExt.palletRule.totalLevel"
            class="width100"
            @input="(val) => {
              formData.storageRuleExt.palletRule.totalLevel = verifyInt(val);
              handleChangeTotalLevel(verifyInt(val))
            }"
          />
          <span class="start">层</span>
          <span class="start margin-left-24">每层</span>
          <el-input
            v-model="levelMap"
            class="width50"

            @input="(val) => {
              levelMap = verifyInt(val);
              handleLevelMapChange()
            }"
          />
          <span class="start">{{ form.bigUnit }}</span>
        </el-form-item>
        <el-form-item
          v-else
          label="码盘规则："
          prop="storageRuleExt.palletRule.totalLevel"
          :rules="[
            {required: true, message: '不能为空', trigger: 'blur'},
          ]"
        >
          <span class="start">共</span>
          <el-input
            v-model="formData.storageRuleExt.palletRule.totalLevel"
            class="width100"
            @input="(val) => {
              formData.storageRuleExt.palletRule.totalLevel = verifyInt(val);
              handleChangeTotalLevel(verifyInt(val))
            }"
          />
          <span class="start">层</span>
        </el-form-item>
      </el-col>
    </el-row>
    <div v-if="!checked">
      <el-row
        v-for="(item) in Object.keys(formData.storageRuleExt.palletRule.levelMap)
          .sort((a, b) => {return b-a})"
        :key="item"
      >
        <el-col :span="7" :push="1">
          <el-form-item
            :label="`${item.match(/\d+/)}层`"
            :prop="`storageRuleExt.palletRule.levelMap.${item}`"
            :rules="[
              {required: true, message: '不能为空', trigger: 'blur'},
            ]"
          >
            <el-input
              v-model="formData.storageRuleExt.palletRule.levelMap[item]"
              class="width50"
              @input="(val) => {
                formData.storageRuleExt.palletRule.levelMap[item] = verifyInt(val);
                handleLevelChange(verifyInt(val))
              }"
            />
            <span class="start">{{ form.bigUnit }}</span>
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <el-row>
      <el-col :span="8">
        <el-form-item
          label="上架库位推荐规则："
          prop="upperLocationRuleExt.appointRule.ruleId"
          :rules="[
            {
              required: !formData.upperLocationRuleExt.inOrderToData,
              message: '请选择', trigger: 'blur'
            },
          ]"
        >
          <nh-remote-select
            v-model="formData.upperLocationRuleExt.appointRule.ruleId"
            :remote-method="getUpLocationOptions"
            :disabled="formData.upperLocationRuleExt.inOrderToData"
            @change="handleChangeUpLocationRuleExt"
          >
            <el-option
              v-for="item in upLocationOptions"
              :key="item.id"
              :label="item.description"
              :value="item.id"
            />
          </nh-remote-select>
        </el-form-item>
      </el-col>
      <el-col :span="15" :offset="1">
        <el-form-item label-width="0">
          <el-checkbox
            v-model="formData.upperLocationRuleExt.inOrderToData"
            label="以单据为准"
            :name="true"
            @change="handleChange"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="下架分配规则："
          prop="lowerLocationRuleExt.appointRule.ruleId"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <nh-remote-select
            v-model="formData.lowerLocationRuleExt.appointRule.ruleId"
            :remote-method="getLowerLocationOptions"
            @change="handleChangeLowerLocationRuleExt"
          >
            <el-option
              v-for="item in lowerLocationOptions"
              :key="item.id"
              :label="item.description"
              :value="item.id"
            />
          </nh-remote-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { ruleStockAllocation, ruleUpperPage } from './api';

import { verifyInt, deepObjectMerge } from '../unit';
import { deposit } from '../constant';

let defaultLowerLocationOptions = [];
let defaultUpLocationOptions = [];
export default {
  name: 'OwnerAboutGoodsInfoDeposit',
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    levelMapNum: {
      type: Number,
      default: null,
    },
  },
  emits: ['updateForm'],
  data() {
    return {
      isInit: true,
      checked: true,
      formData: deepObjectMerge(deposit),
      verifyInt,
      upLocationOptions: [],
      lowerLocationOptions: [],
      loading: {
        handleRemoteRuleBatch: false,
      },
      levelMap: null,
      errorVeneerNumMsg: null,
    };
  },
  watch: {
    form: {
      handler(val) {
        this.init(val);
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init(val) {
      if (this.isInit) {
        this.isInit = false;
        this.getUpLocationOptions();
        await this.getLowerLocationOptions();
      }
      if (val) {
        this.formData = val;
        const { palletRule } = this.form.storageRuleExt;
        const isStandardLevel = [...new Set(Object.values(palletRule.levelMap))];
        if (isStandardLevel.length === 1) {
          [this.levelMap] = isStandardLevel;
          if (this.levelMap) {
            this.checked = true;
          }
        } else {
          this.levelMap = null;
          this.checked = false;
        }
      }
    },
    // 上架
    async getUpLocationOptions(val) {
      if (!val && defaultUpLocationOptions.length > 0) {
        // 如果没有搜索条件，且存在默认上架分配规则选项，使用默认上架分配规则选项
        this.lowerLocationOptions = {
          ...defaultUpLocationOptions,
        };
        return;
      }
      const { records = [] } = await ruleUpperPage({
        description: val,
      }, {
        page: 1,
        size: 99,
      });

      this.upLocationOptions = records;

      if (!val) {
        // 如果没有搜索条件，重置默认下架分配规则选项
        defaultUpLocationOptions = { ...records };
      }
    },
    // 下架
    async getLowerLocationOptions(val) {
      if (!val && defaultLowerLocationOptions.length > 0) {
        // 如果没有搜索条件，且存在默认下架分配规则选项，使用默认下架分配规则选项
        this.lowerLocationOptions = {
          ...defaultLowerLocationOptions,
        };

        return;
      }
      const { records = [] } = await ruleStockAllocation({ description: val }, {
        page: 1,
        size: 1000,
      });

      this.lowerLocationOptions = records;

      if (!val) {
        // 如果没有搜索条件，重置默认下架分配规则选项
        defaultLowerLocationOptions = { ...records };
      }
    },
    handleChangeTotalLevel(n) {
      const levelMap = {};
      const form = {
        ...this.formData,
      };
      const palletRule = {
        totalLevel: n,
        levelMap: { 1: null },
      };
      const num = Number(n);
      if (num) {
        [...new Array(num)].forEach((item, index) => {
          levelMap[`"${index + 1}"`] = null;
        });
        palletRule.levelMap = levelMap;
      }
      form.storageRuleExt = {
        veneerNum: this.formData.storageRuleExt.veneerNum,
        palletRule,
      };
      this.formData = form;
      this.$emit('updateForm', form);
    },
    palletRuleChange() {
      this.formData.storageRuleExt.veneerNum = null;
      Object.keys(this.formData.storageRuleExt.palletRule.levelMap).forEach((item) => {
        this.formData.storageRuleExt.palletRule.levelMap[item] = null;
      });
    },
    handleLevelChange() {
      let total = 0;
      Object.keys(this.formData.storageRuleExt.palletRule.levelMap).forEach((item) => {
        total += this.formData.storageRuleExt.palletRule.levelMap[item] || 0;
      });
      this.formData.storageRuleExt.veneerNum = total;
      this.$emit('updateForm', this.formData);
    },
    handleLevelMapChange() {
      const num = Number(this.levelMap);
      Object.keys(this.formData.storageRuleExt.palletRule.levelMap).forEach((item) => {
        this.formData.storageRuleExt.palletRule.levelMap[item] = num;
      });
      this.formData.storageRuleExt.veneerNum = this.levelMap
      * this.formData.storageRuleExt.palletRule.totalLevel;
      this.$emit('updateForm', this.formData);
    },
    handleChangeUpLocationRuleExt(val) {
      if (val) {
        const findAttr = this.upLocationOptions
          .find((item) => item.id === val);
        const form = {
          ...this.formData,
          upperLocationRuleExt: {
            appointRule: {
              ruleId: val,
              ruleName: findAttr.description,
            },
          },
        };
        this.formData = form;
        this.$emit('updateForm', form);
      }
    },
    handleChangeLowerLocationRuleExt(val) {
      if (val) {
        const findAttr = this.lowerLocationOptions
          .find((item) => item.id === val);
        const form = {
          ...this.formData,
          lowerLocationRuleExt: {
            appointRule: {
              ruleId: val,
              ruleName: findAttr.description,
            },
          },
        };
        this.formData = form;
        this.$emit('updateForm', form);
      }
    },
    handleChange() {
      if (this.formData.upperLocationRuleExt.inOrderToData) {
        const form = this.formData;
        form.upperLocationRuleExt.appointRule = {
          ruleId: null,
          ruleName: null,
        };
      }

      this.$emit('updateForm', this.formData);
    },
    // 校验
    async validateForm() {
      try {
        await this.$refs.form.validate();
        const { levelMap } = this.formData.storageRuleExt.palletRule;
        const numArr = Object.keys(levelMap).map((item) => levelMap[item]);
        if (this.formData.storageRuleExt
          .veneerNum !== numArr.reduce((prev, curr) => prev + curr)) {
          this.errorVeneerNumMsg = '单板数量应等于层数数量之和';
          return false;
        }
        this.errorVeneerNumMsg = null;
        return true;
      } catch (error) {
        return false;
      }
    },
    validateLevel(rule, value, callback) {
      if (!this.formData.storageRuleExt.palletRule.totalLevel) {
        return callback(new Error('请输入层数'));
      }
      if (!this.levelMap) {
        return callback(new Error('请输入每层数量'));
      }
      return callback();
    },
  },
};
</script>
<style lang="scss" scoped>
.width50 {
  width: 50px;
}

.width100 {
  width: 100px;
}

.width120 {
  width: 120px;
}

.margin-left-24 {
  margin-left: 24px;
}
</style>
