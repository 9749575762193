const skuInfo = {
  shipperCode: null,
  shipperId: null,
  shipperName: null,
  goodsName: null,
  internationalCode: null,
  shipperGoodsCode: null,
  goodsTypeName: null,
  partnerCode: null,
  goodsLevel: [],
  goodsLevel1: null,
  goodsLevel2: null,
  goodsLevel3: null,
  partnerName: null,
  goodsSpecifications: null,
  thirdProCode: null,
};
const metering = {
  bigUnit: null,
  smallUnit: null,
  conversionRate: null,
  defaultUnit: null,
  weight: null,
  goodsLength: null,
  goodsWide: null,
  goodsHeight: null,
  goodsPrice: null,
  isCopyCode: null,
  auxiliaryUnits: null,
  veneerWeight: null,
  chargeWeight: null,
  goodsPickType: null,
};
const qualityControl = {
  isOverWarehousePick: null,
  blockGoodsNum: null,
  qualityControlExt: {
    isShelfLife: true,
    shelfLife: null,
    dueInDay: null,
    dueOutDay: null,
    isOverWarehouse: null,
    isUnFreeze: null,
    isFragile: null,
    temperatureLayer: null,
    safeStock: null,
    batchAttributeId: null,
    batchAttributeName: null,
    isImport: null,
  },
};
const deposit = {
  storageRuleExt: {
    veneerNum: null,
    palletRule: {
      totalLevel: null,
      levelMap: {
        1: null,
      },
    },
  },
  upperLocationRuleExt: {
    appointRule: {
      ruleId: null,
      ruleName: null,
    },
    inOrderToData: null,
  },
  lowerLocationRuleExt: {
    appointRule: {
      ruleId: null,
      ruleName: null,
    },
  },
};
const initForm = {
  ...skuInfo, ...metering, ...qualityControl, ...deposit,
};
export {
  skuInfo, metering, qualityControl, deposit,
  initForm,
};
