import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 商品表新增、编辑
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11348
 */
export function warehouseGoodsUpsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/upsert', data);
}
/**
 * @description: 商品表查看详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11350
 */
export function warehouseGoodsDetail(data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/detail', data);
}
