<template>
  <el-form
    ref="form"
    :model="formData"
    label-width="160px"
    :disabled="disabled"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="货主："
          prop="shipperId"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <nh-shipper-selector
            v-model:shipperId="formData.shipperId"
            :disabled="isEdit"
            @change="handleChangeShipper"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="货品名称："
          prop="goodsName"
          :rules="[
            { required: true, message: '请输入', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="formData.goodsName"
            maxlength="100"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="货品条码："
          prop="internationalCode"
        >
          <el-input
            v-model="formData.internationalCode"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="货主货品编码："
          prop="shipperGoodsCode"
          :rules="[
            { required: true, message: '请输入', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="formData.shipperGoodsCode"
            maxlength="30"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="货品类别："
          prop="goodsTypeName"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="formData.goodsTypeName"
            placeholder="--请选择--"
            @change="handleChange"
          >
            <el-option
              v-for="(item, index) in GOODS_TYPE"
              :key="index"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="品牌："
          prop="partnerCode"
        >
          <el-input
            v-model="formData.partnerCode"
            maxlength="15"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="三级分类："
          prop="goodsLevel"
          :rules="[
            {type:'array', required: true, message: '请选择', trigger: 'change' },
          ]"
        >
          <el-cascader
            v-model="formData.goodsLevel"
            class="goods-sku-cascader"
            :options="goodsLevelOptions"
            :props="{
              expandTrigger: 'hover',
              label: 'name',
              value: 'name'
            }"
            @change="handleGoodsLevelChange"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="供应商："
          prop="partnerName"
        >
          <el-input
            v-model="formData.partnerName"
            maxlength="25"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item
          label="规格："
          prop="goodsSpecifications"
          :rules="[
            { required: true, message: '请输入', trigger: 'change' },
          ]"
        >
          <el-input
            v-model="formData.goodsSpecifications"
            maxlength="15"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="第三方货品编码："
          prop="thirdProCode"
        >
          <el-input
            v-model="formData.thirdProCode"
            maxlength="50"
            @input="handleChange"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import loadingMixin from '@thales/loading';
import { getGoodsLevel } from './api';
import mixin from './mixin';
import { skuInfo } from '../constant';
import { deepObjectMerge } from '../unit';

export default {
  name: 'OwnerAboutGoodsInfoSkuInfo',
  mixins: [loadingMixin, mixin],
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateForm'],
  data() {
    return {
      isInit: true,
      formData: deepObjectMerge(skuInfo),
      shipper: {},
      goodsLevelOptions: [],
      loading: {
        handleRemoteRuleBatch: false,
        init: false,
      },
    };
  },
  watch: {
    form: {
      handler(val) {
        this.init(val);
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init(val) {
      if (this.isInit) {
        this.isInit = false;
        await this.getGoodsLevel();
      }
      if (val) {
        this.formData = val;
      }
    },
    async getGoodsLevel() {
      const resp = await getGoodsLevel();
      this.goodsLevelOptions = this.readNodes(resp);
    },
    // 移除空children
    readNodes(nodes = []) {
      const arr = [];
      nodes.forEach((item, index) => {
        arr.push({
          name: item.name,
          id: item.id,
          goodsTypeName: item.goodsTypeName,
          isShelfLife: item.isShelfLife,
        });
        if (item.children && item.children.length > 0) {
          arr[index].children = this.readNodes(item.children);
        }
      });
      return arr;
    },
    handleChange() {
      this.$emit('updateForm', this.formData);
    },
    // 选择货主
    handleChangeShipper(val) {
      if (val) {
        const form = {
          ...this.formData,
          shipperCode: val.customerCode,
          shipperName: val.shipperName,
        };
        this.$emit('updateForm', form);
      }
    },
    // 级联
    handleGoodsLevelChange(val) {
      this.goodsLevelOptions.forEach((item, index) => {
        if (item.name === val[0]) {
          this.goodsLevelOptions[index].children.forEach((vals) => {
            if (vals.name === val[1]) {
              vals.children.forEach((value) => {
                if (value.name === val[2]) {
                  if (value.goodsTypeName) this.formData.goodsTypeName = value.goodsTypeName;
                  this.formData.isShelfLife = value.isShelfLife;
                  this.formData.flag = true;
                }
              });
            }
          });
        }
      });
      const [goodsLevel1, goodsLevel2, goodsLevel3] = val;
      const form = {
        ...this.formData,
        goodsLevel1,
        goodsLevel2,
        goodsLevel3,
        goodsLevel: val,
      };
      this.$emit('updateForm', form);
    },
    // 校验
    async validateForm() {
      try {
        await this.$refs.form.validate();
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
