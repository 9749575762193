export default {
  computed: {
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
    GOODS_UNIT() {
      return this.$dictionaryFiledMap.GOODS_UNIT || [];
    },
    GOODS_TYPE() {
      return this.$dictionaryFiledMap.GOODS_TYPE || [];
    },
  },
};
