<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchForm"
        mode="search"
        :fields="searchFields"
        @submit="handleSearch"
        @reset="handleSearch"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="searchForm.shipperId"
          />
        </template>
        <template #classification>
          <el-cascader
            v-model="searchForm.classification"
            clearable
            :options="cascader"
          />
        </template>
      </PlForm>
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getReservoirList"
        :data="reservoirList"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="getReservoirList"
      >
        <template #tableHeaderRight>
          <nh-button
            :track="{
              trackName: '基础数据/货主相关/货主信息/[新增]'
            }"
            @click="headleAdd"
          >
            新增
          </nh-button>
          <nh-button
            :track="{
              trackName: '基础数据/货主相关/货主信息/[导出]'
            }"
            @click="handleExport"
          >
            导出
          </nh-button>
          <nh-button
            :track="{
              trackName: '基础数据/货主相关/货主信息/[导入]'
            }"
            @click="handleImport"
          >
            导入
          </nh-button>
        </template>
        <template #action="{row}">
          <nh-button type="text" @click="handleReGoodsEdit(row)">
            编辑
          </nh-button>
          <nh-button type="text" @click="handleReGoodsDetail(row)">
            详情
          </nh-button>
          <!-- <nh-link
            :to="{
              path:'goods/goodsInfo',
              query:{
                id: row.id,
                warehouseCode: row.warehouseCode,
                disabled: 'disabled',
              }
            }"
            text="详情"
          /> -->
        </template>
      </PlTable>
    </pl-block>
    <nh-data-import
      ref="DataImport"
      :err-msg-list="errMsgList"
      :is-upload="isUpload"
      :track="{
        name_zh: '基础数据/货主相关/货主货品信息/弹窗-导入',
        trackName: $route.path
      }"
      @upload="warehouseGoodsImport"
      @downLoadTemplate="handleDownTemplate"
      @submitSuccess="handleGoodsImportSubmitSuccess"
      @cancel="handleGoodsImportSubmitSuccess"
      @clearAll="handleGoodsImportClearAll"
    />
    <goods-info ref="goodsInfo" @onSuccess="getReservoirList" />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import { FormType } from '@/constant/form';
import Base from '@/constant/pageNames/base';
import goodsInfo from './goodsInfo/index.vue';
import mixin from './mixin';
import { DICTIONARY, WAREHOUSE_STATUS, TABLECOLUMN } from './fileds';
import {
  getList, warehouseGoodsExport, warehouseGoodsImport, downLoadTemplate,
} from './api';

export default {
  name: Base.GOODS,
  components: { goodsInfo },
  mixins: [loadingMixin, mixin],
  data() {
    return {
      DICTIONARY,
      WAREHOUSE_STATUS,
      TABLECOLUMN,
      reservoirList: [],
      searchForm: {
        shipperId: null,
      },
      loading: {
        handleExport: false,
        getReservoirList: false,
      },
      isUpload: false,
      errMsgList: [],
    };
  },
  computed: {
    searchFields() {
      return [
        {
          label: '货主',
          prop: 'shipperId',
        },
        {
          label: '货品编码',
          prop: 'goodsCode',
          component: FormType.INPUT,
        },
        {
          label: '货品名称',
          prop: 'goodsName',
          component: FormType.INPUT,
        },
        {
          label: '状态',
          prop: 'status',
          component: FormType.SELECT,
          options: WAREHOUSE_STATUS,
        },
        {
          label: '货品分类',
          prop: 'classification',
        },
        {
          label: '温层',
          prop: 'temperatureLayer',
          component: FormType.SELECT,
          options: this.zoneTemperature,
        },
      ];
    },
  },
  created() {
    this.init();
  },
  activated() {
    this.getReservoirList();
  },
  methods: {
    init() {
      this.getReservoirList();
    },
    handleSearch() {
      this.pagination.page = 1;
      this.getReservoirList();
    },
    async getReservoirList() {
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await getList(params, pagination);
      this.reservoirList = resp.records.map((item) => ({
        ...item,
        extTxt:
          Object.prototype.toString.call(item.ext) === '[object Object]'
            ? Object.keys(item.ext)
              .map((name) => item.ext[name])
              .join('、')
            : '',
      }));
      this.pagination.total = resp.total;
    },
    buildParams() {
      let goodsLevel1 = null;
      let goodsLevel2 = null;
      let goodsLevel3 = null;
      const { classification } = this.searchForm;
      if (Array.isArray(classification)) {
        [goodsLevel1 = null, goodsLevel2 = null, goodsLevel3 = null] = classification;
      }
      return {
        ...this.searchForm,
        goodsLevel1,
        goodsLevel2,
        goodsLevel3,
      };
    },
    handleReGoodsEdit(row) {
      this.$refs.goodsInfo.init({
        id: row.id,
        warehouseCode: row.warehouseCode,
      });
    },
    handleReGoodsDetail(row) {
      this.$refs.goodsInfo.init({
        id: row.id,
        warehouseCode: row.warehouseCode,
        disabled: 'disabled',
      });
    },
    filterLabel(val, arr) {
      const filterArr = arr && arr.find((item) => item.code === val);
      return filterArr ? filterArr.label : '';
    },
    handleImport() {
      this.$refs.DataImport.showDialog();
    },
    async warehouseGoodsImport(fd) {
      const errorMsg = await warehouseGoodsImport(fd);
      this.isUpload = true;
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
    },
    async handleDownTemplate() {
      await downLoadTemplate({ templateId: 'goods_import_template' });
    },
    handleGoodsImportSubmitSuccess() {
      this.handleGoodsImportClearAll();
      this.$refs.DataImport.handleClose();
      this.getReservoirList();
    },
    handleGoodsImportClearAll() {
      this.isUpload = false;
      this.errMsgList = [];
    },
    async  handleExport() {
      const params = this.buildParams();
      await warehouseGoodsExport(params);
    },
    // 新增
    headleAdd() {
      this.$refs.goodsInfo.init({});
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
